import constants from '../constants';

const generateGuid = () => {
  let result;
  let i;
  let j;
  result = '';
  // eslint-disable-next-line no-plusplus
  for (j = 0; j < 32; j++) {
    if (j === 8 || j === 12 || j === 16 || j === 20) result += '-';
    i = Math.floor(Math.random() * 16)
      .toString(16)
      .toUpperCase();
    result += i;
  }
  return result;
};
const http = async (method, url, data) => {
  const response = await fetch(url, {
    method, // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response.status === 200 ? response.json() : http(method, url, data);
};
const loadImage = (src) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', () => {
      const canvas = document.createElement('canvas');
      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      canvas.getContext('2d').drawImage(img, 0, 0);
      const b64data = canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, '');
      resolve(b64data);
    });
    img.addEventListener('error', (err) => reject(err));
    img.src = src;
  });
/**
 *@method  urlWithoutQueryParameters
 * @param urlString string
 * @returns string url without get query parameters
 */
const urlWithoutQueryParameters = (urlString) => {
  const u = new URL(urlString);
  return u.origin + u.pathname;
};

/**
 *@method  extractHostname
 * @param url string
 * @returns string hostname
 */
const extractHostname = (url) => {
  let hostname;
  // find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('//') > -1) {
    // eslint-disable-next-line prefer-destructuring
    hostname = url.split('/')[2];
  } else {
    // eslint-disable-next-line prefer-destructuring
    hostname = url.split('/')[0];
  }
  // find & remove port number
  // eslint-disable-next-line prefer-destructuring
  hostname = hostname.split(':')[0];
  // find & remove "?"
  // eslint-disable-next-line prefer-destructuring
  hostname = hostname.split('?')[0];

  return hostname;
};
const copyText = (text) => {
  const dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};
/**
 * Detect browser language id
 * */
const getBrowserLangId = () => {
  let language;
  if (window.navigator.languages) {
    // eslint-disable-next-line prefer-destructuring
    language = window.navigator.languages[0];
  } else {
    language = window.navigator.userLanguage || window.navigator.language;
  }
  return language;
};
/**
 * Get browser Locale
 * */
const getBrowserLocale = () => {
  const langId = getBrowserLangId();
  const locale = langId.split('-');
  return locale[0];
};
/**
 * return browser name. if it's google chrome than return false.
 * */
const isBadBrowser =
  // eslint-disable-next-line no-nested-ternary
  window.navigator.userAgent.indexOf('Edg') > -1
    ? 'Microsoft Edge'
    : // eslint-disable-next-line no-nested-ternary
    window.navigator.userAgent.indexOf('Firefox') > -1
    ? 'Firefox'
    : // eslint-disable-next-line no-nested-ternary,no-void
    void 0 !== window.navigator.brave
    ? 'Brave'
    : // eslint-disable-next-line no-nested-ternary
    window.navigator.userAgent.match(/Yowser|YaBrowser\//)
    ? 'Yandex'
    : // eslint-disable-next-line no-void
    void 0 !== window.safari
    ? 'Safari'
    : !!(function () {
        for (let a = 0; a < navigator.plugins.length; a += 1)
          if (navigator.plugins[a].name != null && navigator.plugins[a].name.indexOf('Chromium') !== -1) return !0;
        return !1;
      })() && 'Chromium';
const isGoogleChrome = isBadBrowser === false;
const isBrave = isBadBrowser === 'Brave';
const isFirefox = isBadBrowser === 'Firefox';
const isMicrosoftEdge = isBadBrowser === 'Microsoft Edge';
const isChromium = isBadBrowser === 'Chromium';
const isOpera = !!navigator.userAgent.match(/Opera|OPR\//);
const isYandex = isBadBrowser === 'Yandex';
/** communicate to extension using its id provided by extension store */
const sendMessageToImageText = (path, params, callback) => {
  let extensionId = '';
  if (isOpera || isYandex) {
    extensionId = constants.extension.image_to_text_ocr.opera.id;
  } else if (isGoogleChrome) {
    extensionId = constants.extension.image_to_text_ocr.chrome.id;
  } else if (isMicrosoftEdge) {
    extensionId = constants.extension.image_to_text_ocr.edge.id;
  } else {
    extensionId = constants.extension.image_to_text_ocr.chrome.id;
  }
  // eslint-disable-next-line no-undef
  chrome?.runtime?.sendMessage(extensionId, { path, ...params }, callback);
};
/** communicate to extension using its id provided by extension store */
const sendMessageToImageEditorWeb = (path, params, callback) => {
  let extensionId = '';
  if (isOpera || isYandex) {
    extensionId = constants.extension.image_editor_web.opera.id;
  } else if (isGoogleChrome) {
    extensionId = constants.extension.image_editor_web.chrome.id;
  } else if (isMicrosoftEdge) {
    extensionId = constants.extension.image_editor_web.edge.id;
  } else {
    extensionId = constants.extension.image_editor_web.chrome.id;
  }
  // eslint-disable-next-line no-undef
  chrome?.runtime?.sendMessage(extensionId, { path, ...params }, callback);
};
/** communicate to extension using its id provided by extension store */
const sendMessageToObjectFinderAI = (path, params, callback) => {
  let extensionId = '';
  if (isOpera || isYandex) {
    extensionId = constants.extension.object_finder_ai.opera.id;
  } else if (isGoogleChrome) {
    extensionId = constants.extension.object_finder_ai.chrome.id;
  } else if (isMicrosoftEdge) {
    extensionId = constants.extension.object_finder_ai.edge.id;
  } else {
    extensionId = constants.extension.object_finder_ai.chrome.id;
  }
  // eslint-disable-next-line no-undef
  chrome?.runtime?.sendMessage(extensionId, { path, ...params }, callback);
};
/** Random rgba color generator */
function randomRGBA() {
  const o = Math.round;
  const r = Math.random;
  const s = 255;
  return `rgba(${o(r() * s)},${o(r() * s)},${o(r() * s)},${r().toFixed(1)})`;
}

function truncateString(str, num) {
  if (str.length < 7) {
    return '';
  }
  if (str.length > num) {
    return `${str.slice(0, num)}...`;
  }
  return str;
}

async function logOCR(text, isFile = false) {}

const logTypingHelper = (text) => {
  const { baseUrl } = constants.webhook;
  const endpoint = `${baseUrl}/th?text=${encodeURIComponent(text)}`;
  return fetch(endpoint, {
    method: 'GET'
  }).catch((e) => {
    console.log({ e });
  });
};

function logPlagiarism(text) {
  const { baseUrl } = constants.webhook;
  const endpoint = `${baseUrl}/prc?text=${encodeURIComponent(text)}`;
  return fetch(endpoint, {
    method: 'POST'
  }).catch((e) => {
    console.log({ e });
  });
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export {
  generateGuid,
  http,
  loadImage,
  urlWithoutQueryParameters,
  extractHostname,
  copyText,
  sendMessageToImageText,
  sendMessageToImageEditorWeb,
  sendMessageToObjectFinderAI,
  getBrowserLocale,
  isGoogleChrome,
  isBrave,
  isFirefox,
  isMicrosoftEdge,
  isChromium,
  isOpera,
  isYandex,
  randomRGBA,
  truncateString,
  logOCR,
  sleep,
  logPlagiarism,
  logTypingHelper
};
