import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';

// ===========================|| MAIN LOGO ||=========================== //

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath}>
    <h3>
      {' '}
      <span className="text-2xl">IMAGETEXT.XYZ</span>
    </h3>
  </ButtonBase>
);

export default LogoSection;
