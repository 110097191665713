import React from 'react';

// material-ui
import { makeStyles } from '@mui/styles';
import { Grid, Link, Stack, Typography } from '@mui/material';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import constants from '../../../../constants';
import { useLanguage } from '../../../../context/LanguageContext';

// style constant
const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.warning.light,
    marginTop: '16px',
    marginBottom: '16px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '200px',
      height: '200px',
      border: '19px solid ',
      borderColor: theme.palette.warning.main,
      borderRadius: '50%',
      top: '65px',
      right: '-150px'
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '200px',
      height: '200px',
      border: '3px solid ',
      borderColor: theme.palette.warning.main,
      borderRadius: '50%',
      top: '145px',
      right: '-70px'
    }
  },
  tagLine: {
    color: theme.palette.grey[900],
    opacity: 0.6
  },
  button: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.warning.main,
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.warning.dark
    }
  }
}));

// ===========================|| PROFILE MENU - UPGRADE PLAN CARD ||=========================== //

const UpgradePlanCard = () => {
  const classes = useStyles();
  const { t } = useLanguage();
  const labelJoin = t('labelJoin'); // Join now
  const labelJoinPremium = t('labelJoinPremium'); // Join premium!
  const upCTAlabel = t('upCTAlabel'); // Ad-free and other Great features.
  const upCTAPrice = t('upCTAPrice'); // Just  ₹1050 ~ $13.

  return (
    <Card className={classes.card}>
      <CardHeader>
        {' '}
        <Grid item>
          <Typography variant="h4">{labelJoinPremium}</Typography>
        </Grid>
      </CardHeader>
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="subtitle2" className={classes.tagLine}>
              {upCTAlabel}. {upCTAPrice}
            </Typography>
          </Grid>
          <Grid item>
            <Stack direction="row">
              <AnimateButton>
                <Button
                  onClick={() => {
                    window.location.href = constants.premium.endpoint;
                  }}
                >
                  {labelJoin}
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UpgradePlanCard;
