import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { Card, CardContent, Grid, Typography } from '@mui/material';

// project imports
import constants from '../../../../constants';
import { useLanguage } from '../../../../context/LanguageContext';

// ===========================|| Support Links ||=========================== //

const SupportLinks = () => {
  const { t } = useLanguage();
  const linkTermsAndConditions = t('linkTermsAndConditions'); // Terms and Conditions
  const linkPrivacy = t('linkPrivacy'); // Privacy
  const linkSupport = t('linkSupport'); // Support

  return (
    <Card>
      <CardContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Link to="/privacy">{linkPrivacy}</Link>
          </Grid>
          <Grid item xs={12}>
            <Link to="/terms">{linkTermsAndConditions}</Link>
          </Grid>
          <Grid item xs={12}>
            <a href={constants.support.feedbackForm}>{linkSupport}</a>
          </Grid>
          <Grid item xs={12}>
            <Typography>© imagetext.xyz</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SupportLinks;
