import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// pdfs routing
const Pdfs = Loadable(lazy(() => import('views/pdfs/Default')));

// Plagiarism Checker routing
const PlagiarismChecker = Loadable(lazy(() => import('views/plagiarism-checker')));

// editor routing
const Editor = Loadable(lazy(() => import('views/editor')));

// recognize routing
// const Recognize = Loadable(lazy(() => import('views/recognize')));

// downloads routing
const Downloads = Loadable(lazy(() => import('views/downloads')));

// started routing
const Started = Loadable(lazy(() => import('views/started')));

// screen routing
const Screen = Loadable(lazy(() => import('views/screen')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: '/images',
      element: <DashboardDefault />
    },
    {
      path: '/screen',
      element: <Screen />
    },
    {
      path: '/pdfs',
      element: <Pdfs />
    },
    {
      path: '/plagiarism-checker',
      element: <PlagiarismChecker />
    },
    {
      path: '/editor',
      element: <Editor />
    },
    {
      path: '/started',
      element: <Started />
    },
    {
      path: '/downloads',
      element: <Downloads />
    }
  ]
};

export default MainRoutes;
