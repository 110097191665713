// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// OCR actions
export const OCR_IMAGE_START = 'OCR_IMAGE_START';
export const OCR_IMAGE_START_SUCCESS = 'OCR_IMAGE_START_SUCCESS';
export const OCR_IMAGE_START_FAIL = 'OCR_IMAGE_START_FAIL';
export const OCR_IMAGE_CLEAR = 'OCR_IMAGE_CLEAR';

export const OCR_PDF_START = 'OCR_PDF_START';
export const OCR_PDF_START_SUCCESS = 'OCR_PDF_START_SUCCESS';
export const OCR_PDF_START_FAIL = 'OCR_PDF_START_FAIL';
export const OCR_PDF_CLEAR = 'OCR_PDF_CLEAR';

// Plagiarism detection actions
export const PLAGIARISM_DETECTION_START = 'PLAGIARISM_DETECTION_START';
export const PLAGIARISM_DETECTION_SUCCESS = 'PLAGIARISM_DETECTION_SUCCESS';
export const PLAGIARISM_DETECTION_FAIL = 'PLAGIARISM_DETECTION_FAIL';
export const PLAGIARISM_DETECTION_CLEAR = 'PLAGIARISM_DETECTION_CLEAR';
