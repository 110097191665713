// action - state management
import * as actionTypes from './actions';

export const initialState = {
    initial: true,
    isLoading: false,
    text: null,
    error: null
};

// ===========================|| CUSTOMIZATION REDUCER ||=========================== //

const ocrReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PLAGIARISM_DETECTION_START:
            return {
                ...state,
                initial: false,
                isLoading: true
            };
        case actionTypes.PLAGIARISM_DETECTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                text: action.data,
                result: action.data,
                initial: false
            };
        case actionTypes.PLAGIARISM_DETECTION_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                text: null,
                initial: false
            };
        case actionTypes.PLAGIARISM_DETECTION_CLEAR:
            return initialState;
        default:
            return state;
    }
};

export default ocrReducer;
