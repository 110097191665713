// assets
import {
  IconDashboard,
  IconDeviceAnalytics,
  IconPhoto,
  IconFileText,
  IconWritingSign,
  IconManualGearbox,
  IconApps,
  IconInfoSquare,
  IconClipboardCheck,
  IconTypography
} from '@tabler/icons';

// constant
const icons = {
  IconDashboard,
  IconDeviceAnalytics,
  IconPhoto,
  IconFileText,
  IconWritingSign,
  IconManualGearbox,
  IconApps,
  IconInfoSquare,
  IconClipboardCheck,
  IconTypography
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const dashboard = {
  id: 'pagesLabel',
  title: 'Pages',
  type: 'group',
  children: [
    {
      id: 'scanImagesLabel',
      title: 'Scan Images',
      type: 'item',
      url: '/',
      icon: icons.IconPhoto,
      breadcrumbs: false
    },
    {
      id: 'scanPdfLabel',
      title: 'Scan Pdfs',
      type: 'item',
      url: '/pdfs',
      icon: icons.IconFileText,
      breadcrumbs: false
    },
    {
      id: 'editImagesLabel',
      title: 'Edit Images',
      type: 'item',
      url: '/editor',
      icon: icons.IconWritingSign,
      breadcrumbs: false
    },
    {
      id: 'downloadLabel',
      title: 'Downloads',
      type: 'item',
      url: '/downloads',
      icon: icons.IconApps,
      breadcrumbs: false
    },
    {
      id: 'gettingStartedLabel',
      title: 'Getting Started',
      type: 'item',
      url: '/started',
      icon: icons.IconInfoSquare,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
