import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

// project imports
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const Terms = Loadable(lazy(() => import('views/terms')));
const Privacy = Loadable(lazy(() => import('views/privacy')));

// ===========================|| AUTHENTICATION ROUTING ||=========================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/terms',
            element: <Terms />
        },
        {
            path: '/privacy',
            element: <Privacy />
        }
    ]
};

export default AuthenticationRoutes;
