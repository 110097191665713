import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LegalRoutes from './legalRoutes';
import OtherRoutes from './otherRoutes';
// ===========================|| ROUTING RENDER ||=========================== //

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, LegalRoutes, OtherRoutes]);
}
