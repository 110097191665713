import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import ocrReducer from './ocrReducer';
import plagiarismReducer from './plagiarismReducer';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    customization: customizationReducer,
    ocr: ocrReducer,
    plagiarism: plagiarismReducer
});

export default reducer;
