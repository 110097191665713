const constants = {
  freeOCRLimit: 500,
  waitTimeInMiliSeconds: 100,
  webhook: {
    baseUrl: 'https://webhook.fxnoob.workers.dev'
  },
  backend: {
    baseURL: 'https://api.imagetext.xyz/ocr'
  },
  extension: {
    image_to_text_ocr: {
      tutorial: 'https://www.youtube.com/watch?v=c9hvz21ESys',
      chrome: {
        id: 'jgjlejdhmfpimggbicpffmpbnalcnhoo',
        url: 'https://chrome.google.com/webstore/detail/image-to-text/jgjlejdhmfpimggbicpffmpbnalcnhoo'
      },
      opera: {
        id: 'nlfnkkhgkofnahjdookfhcaagemjdabe',
        url: 'https://addons.opera.com/en/extensions/details/image-to-text-ocr/'
      },
      edge: {
        id: 'icgbomdceijejlokdmjpmgkojiliphma',
        url: 'https://microsoftedge.microsoft.com/addons/detail/icgbomdceijejlokdmjpmgkojiliphma'
      },
      firefox: {
        url: 'https://addons.mozilla.org/addon/image-to-text-ocr/'
      }
    },
    image_editor_web: {
      chrome: {
        id: 'cddmngomnoefbecedkiokcfhccohppil',
        url: 'https://chrome.google.com/webstore/detail/cddmngomnoefbecedkiokcfhccohppil'
      },
      opera: {
        id: 'jmelpilbhinmckilfapacicllplhkgin',
        url: 'https://addons.opera.com/en/extensions/details/image-editor-web/'
      },
      edge: {
        id: 'mmehjkpbnaclkoinedpndonlbjjbfjbp',
        url: 'https://microsoftedge.microsoft.com/addons/detail/mmehjkpbnaclkoinedpndonlbjjbfjbp'
      },
      firefox: {
        url: 'https://addons.mozilla.org/addon/image-editor-web/'
      }
    },
    object_finder_ai: {
      chrome: {
        id: 'bhfiacaoiiaajdjijafijbagomlkakfh',
        url: 'https://chrome.google.com/webstore/detail/bhfiacaoiiaajdjijafijbagomlkakfh'
      },
      opera: {
        id: 'nohdfknodnmbcclbhcadlgknjjidgafi',
        url: 'https://addons.opera.com/en/extensions/details/object-finder-ai/'
      },
      edge: {
        id: 'egmbdpbpplfcmcfpaengneloicfmcdao',
        url: 'https://microsoftedge.microsoft.com/addons/detail/egmbdpbpplfcmcfpaengneloicfmcdao'
      },
      firefox: {
        url: 'https://addons.mozilla.org/addon/object-finder-ai/'
      }
    },
    dictionary: {
      chrome: {
        url: 'https://chrome.google.com/webstore/detail/dictionary-oxford-webster/pbknajakomimhnnaiddkbddboifjolbf'
      },
      firefox: {
        url: 'https://addons.mozilla.org/en-US/firefox/addon/dictionary/'
      }
    },
    voice_typing: {
      chrome: {
        url: 'https://chrome.google.com/webstore/detail/voice-typing/hmpihaioaacpehkghnkmnmgmihalkmdf'
      }
    },
    saveTo: {
      chrome: {
        url: 'https://chrome.google.com/webstore/detail/save-to/ofifhemkgcbeneclidajibkcjllgmjfp'
      }
    },
    translate: {
      chrome: {
        url: 'https://chrome.google.com/webstore/detail/translate/pobhnbdhbklfjhlhpmoegnneonlnkbpo'
      }
    }
  },
  premium: {
    endpoint: 'https://app.imagetext.xyz/',
    pricing: {
      short: '$13',
      description: '$13 for a lifetime.',
      full: 'inr 1050 ~ $13'
    }
  },
  support: {
    feedbackForm: 'https://forms.gle/iaFrarBtMp2YDFhB6',
    youtube: 'https://www.youtube.com/fxnoob'
  }
};
export default constants;
